/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import { db, functions } from "@/firebase/firebaseConfig";
import router from "@/router";

export default {
  async forgotPassword(context, payload) {
    let email = payload.email;
    firebase.auth().languageCode = payload.locale;
    await firebase.auth().sendPasswordResetEmail(email);
  },
  async resetPasswordVerification(context, payload) {
    let code = payload.code;
    return await firebase.auth().verifyPasswordResetCode(code);
  },
  async resetPasswordConfirmation(context, { code, password }) {
    return await firebase.auth().confirmPasswordReset(code, password);
  },

  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
      otp: payload.otp,
      otpConfig: payload.otpConfig,
    };

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)

        // If success try to login
        .then(function () {
          dispatch("login", newPayload);
        })

        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    } else {
      // Try to login
      dispatch("login", newPayload);
    }
  },
  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    state.isPenalRetailUserLoggedIn().then((isUserLoggedIn) => {
      if (isUserLoggedIn) {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation();

        payload.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }

      let loginUser = functions.httpsCallable("loginUserProcess");

      firebase
        .auth()
        .signInWithEmailAndPassword(
          payload.userDetails.email,
          payload.userDetails.password
        )
        .then(async () => {
          loginUser({ otp: payload.userDetails.otp }).then(async ({ data }) => {
            if (data.status === "FINISH") {
              //Cerramos sesión anterior
              firebase
                .auth()
                .signOut()
                .then(() => {
                  //Nos logueamos con el custom token recibido ya que es el único que nos permite acceder a todos los datos
                  firebase
                    .auth()
                    .signInWithCustomToken(data.token)
                    .then(async (result) => {
                      // Set FLAG username update required for updating username
                      let isUsernameUpdateRequired = false;

                      // if username is provided and updateUsername FLAG is true
                      // set local username update FLAG to true
                      // try to update username
                      if (
                        payload.updateUsername &&
                        payload.userDetails.displayName
                      ) {
                        isUsernameUpdateRequired = true;

                        dispatch("updateUsername", {
                          user: result.user,
                          username: payload.userDetails.displayName,
                          notify: payload.notify,
                          isReloadRequired: true,
                        });
                      }

                      const idToken = await firebase
                        .auth()
                        .currentUser.getIdTokenResult();

                      if (idToken.claims.penalRetailAllowed) {
                        let role = null;
                        let locale = null;
                        let customers = null;
                        let customer = null;
                        let establishments = null;

                        let availableTypes = [];

                        const doc = await db
                          .collection("users")
                          .doc(result.user.uid)
                          .get();
                        if (doc.exists) {
                          let userData = doc.data();

                          role = userData.role;
                          locale = userData.locale;

                          if (
                            userData.role === "customer" ||
                            userData.role === "supercustomer"
                          ) {
                            customer = {
                              id: userData.customer.id,
                              alias: userData.customer.alias,
                            };

                            if (userData.role === "customer") {
                              establishments = userData.establishments;
                            }

                            //Obtenemos el dato de los tipos de casos que tiene asociado el cliente
                            const customerData = (
                              await db
                                .collection("customers")
                                .doc(userData.customer.id)
                                .get()
                            ).data();
                            availableTypes = customerData.casesTypes.map(
                              (c) => c.id
                            );
                          } else {
                            if (userData.role === "lawyer") {
                              customers = userData.customers;
                              establishments = userData.establishments;
                            }

                            availableTypes = userData.casesTypes;
                          }
                        } else {
                          console.log("No such document!");
                        }

                        let user = {
                          uid: result.user.uid,
                          displayName: result.user.email,
                          email: result.user.email,
                          customer,
                          establishments,
                          customers,
                          role,
                          availableTypes,
                          locale,
                        };

                        if (!isUsernameUpdateRequired) {
                          await commit("UPDATE_USER_INFO", user, {
                            root: true,
                          });
                          router.push(router.currentRoute.query.to || "/");
                        }
                      } else {
                        //No está habilitado aún... que pasó?
                        payload.notify({
                          time: 2500,
                          title: "Error",
                          text: "Invalid login",
                          iconPack: "feather",
                          icon: "icon-alert-circle",
                          color: "danger",
                        });
                      }

                      if (payload.closeAnimation) payload.closeAnimation();
                    })
                    .catch((err) => {
                      // Close animation if passed as payload
                      if (payload.closeAnimation) payload.closeAnimation();
                      payload.notify({
                        time: 2500,
                        title: "Error",
                        text: err.message,
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        color: "danger",
                      });
                    });
                });
            } else if (["OTP", "OTP_CONFIG"].includes(data.status)) {
              if (data.status === "OTP_CONFIG") {
                //Mandamos configurar
                payload.otpConfig();
              } else {
                payload.otp();
              }
            } else if (data.status === "OTP_ERROR") {
              // Close animation if passed as payload
              if (payload.closeAnimation) payload.closeAnimation();

              payload.notify({
                time: 2500,
                title: "Error",
                text: "Invalid OTP code",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            } else {
              // Close animation if passed as payload
              if (payload.closeAnimation) payload.closeAnimation();

              payload.notify({
                time: 2500,
                title: "Error",
                text: data.status,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        })
        .catch((err) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    });
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },
  registerUser({ dispatch }, payload) {
    // create user using firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )
      .then(
        () => {
          payload.notify({
            title: "Account Created",
            text: "You are successfully registered!",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
          });

          const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            updateUsername: true,
          };
          dispatch("login", newPayload);
        },
        (error) => {
          payload.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      );
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          // If there's user data in response
          if (response.data.userData) {
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || "/");

            // Set accessToken
            localStorage.setItem("accessToken", response.data.accessToken);

            // Update user details
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.accessToken);

            resolve(response);
          } else {
            reject({ message: "Wrong Email or Password" });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } =
      payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(displayName, email, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || "/");

          // Update data in localStorage
          localStorage.setItem("accessToken", response.data.accessToken);
          commit("UPDATE_USER_INFO", response.data.userData, { root: true });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response);
      });
    });
  },
};
