/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "./es.json";
import fr from "./fr.json";

Vue.use(VueI18n);

let locale = "es";

let userLocale = navigator.language || navigator.userLanguage;

if (userLocale) {
  userLocale = userLocale.split("-")[0];
  if (["fr"].includes(userLocale.toLowerCase())) {
    locale = "fr";
  }
}

export default new VueI18n({
  locale, // set default locale
  messages: { es, fr },
});
