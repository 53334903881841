/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: (state) => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl";
    else if (state.windowWidth >= 992) return "lg";
    else if (state.windowWidth >= 768) return "md";
    else if (state.windowWidth >= 576) return "sm";
    else return "xs";
  },

  allCases: ({ allCases, allEstablishments, AppActiveUser }) => {
    const role = AppActiveUser.role;
    let cases = allCases;
    if (allCases && allEstablishments) {
      if (role === "customer" && (AppActiveUser.establishments || []).length) {
        const establishmentsIds = AppActiveUser.establishments;
        cases = allCases.filter((x) =>
          establishmentsIds.includes(x.establishment.id)
        );
      } else if (
        role === "lawyer" &&
        ((AppActiveUser.customers || []).length ||
          (AppActiveUser.establishments || []).length)
      ) {
        let lawyerCustomers = AppActiveUser.customers || [];
        let lawyerEstablishments = AppActiveUser.establishments || [];

        //Cargamos los establecimientos filtrados explícitamente
        let filteredEstablishments = allEstablishments.filter((e) =>
          lawyerEstablishments.includes(e.id)
        );

        //Vemos los clientes que tiene todos sus clientes
        let notFilteredCustomers = lawyerCustomers.filter(
          (cid) => !filteredEstablishments.some((e) => e.customer.id === cid)
        );
        //Buscamos los establecimientos de los customers no filtrados
        let notFilteredCustomersEstablishments = allEstablishments.filter((e) =>
          notFilteredCustomers.includes(e.customer.id)
        );

        const establishmentsIds = [
          ...new Set(
            [].concat(
              filteredEstablishments.map((x) => x.id),
              notFilteredCustomersEstablishments.map((x) => x.id)
            )
          ),
        ];
        cases = allCases.filter((x) =>
          establishmentsIds.includes(x.establishment.id)
        );
      }
    }

    return cases;
  },
  allCivilCases: ({ allCivilCases, AppActiveUser }) => {
    const role = AppActiveUser.role;
    let civilCases = allCivilCases;

    if (allCivilCases) {
      if (role === "lawyer" && (AppActiveUser.customers || []).length) {
        let lawyerCustomers = AppActiveUser.customers || [];

        civilCases = allCivilCases.filter((x) =>
          lawyerCustomers.includes(x.customer.id)
        );
      }
    }

    return civilCases;
  },
  allIssues: ({ allIssues, allEstablishments, AppActiveUser }) => {
    const role = AppActiveUser.role;
    let issues = allIssues;
    if (allIssues && allEstablishments) {
      if (role === "customer" && (AppActiveUser.establishments || []).length) {
        const establishmentsIds = AppActiveUser.establishments;
        issues = allIssues.filter((x) =>
          establishmentsIds.includes(x.establishment.id)
        );
      } else if (
        role === "lawyer" &&
        ((AppActiveUser.customers || []).length ||
          (AppActiveUser.establishments || []).length)
      ) {
        let lawyerCustomers = AppActiveUser.customers || [];
        let lawyerEstablishments = AppActiveUser.establishments || [];

        //Cargamos los establecimientos filtrados explícitamente
        let filteredEstablishments = allEstablishments.filter((e) =>
          lawyerEstablishments.includes(e.id)
        );

        //Vemos los clientes que tiene todos sus clientes
        let notFilteredCustomers = lawyerCustomers.filter(
          (cid) => !filteredEstablishments.some((e) => e.customer.id === cid)
        );
        //Buscamos los establecimientos de los customers no filtrados
        let notFilteredCustomersEstablishments = allEstablishments.filter((e) =>
          notFilteredCustomers.includes(e.customer.id)
        );

        const establishmentsIds = [
          ...new Set(
            [].concat(
              filteredEstablishments.map((x) => x.id),
              notFilteredCustomersEstablishments.map((x) => x.id)
            )
          ),
        ];
        issues = allIssues.filter((x) =>
          establishmentsIds.includes(x.establishment.id)
        );
      }
    }

    return issues;
  },
  allCustomers: ({ allCustomers, AppActiveUser }) => {
    const role = AppActiveUser.role;
    let customers = allCustomers;
    if (allCustomers) {
      if (role === "lawyer" && (AppActiveUser.customers || []).length) {
        let lawyerCustomers = AppActiveUser.customers || [];
        customers = allCustomers.filter((x) => lawyerCustomers.includes(x.id));
      }
    }

    return customers;
  },
  allZones: ({ allZones, AppActiveUser }) => {
    const role = AppActiveUser.role;
    if (role === "customer" || role === "lawyer") {
      //El customer y el lawyer no tienen acceso a las zonas
      return [];
    } else {
      return allZones;
    }
  },
  allEstablishments: ({ allEstablishments, allCustomers, AppActiveUser }) => {
    const role = AppActiveUser.role;
    let establishments = allEstablishments;
    if (allEstablishments && allCustomers) {
      if (role === "customer" && (AppActiveUser.establishments || []).length) {
        const establishmentsIds = AppActiveUser.establishments;
        establishments = allEstablishments.filter((x) =>
          establishmentsIds.includes(x.id)
        );
      } else if (
        role === "lawyer" &&
        (AppActiveUser.establishments || []).length
      ) {
        let lawyerCustomers = AppActiveUser.customers || [];
        let lawyerEstablishments = AppActiveUser.establishments || [];

        //Cargamos los establecimientos filtrados explícitamente
        let filteredEstablishments = allEstablishments.filter((e) =>
          lawyerEstablishments.includes(e.id)
        );

        //Vemos los clientes que tiene todos sus clientes
        let notFilteredCustomers = lawyerCustomers.filter(
          (cid) => !filteredEstablishments.some((e) => e.customer.id === cid)
        );
        //Buscamos los establecimientos de los customers no filtrados
        let notFilteredCustomersEstablishments = allEstablishments.filter((e) =>
          notFilteredCustomers.includes(e.customer.id)
        );

        const establishmentsIds = [
          ...new Set(
            [].concat(
              filteredEstablishments.map((x) => x.id),
              notFilteredCustomersEstablishments.map((x) => x.id)
            )
          ),
        ];
        establishments = allEstablishments.filter((x) =>
          establishmentsIds.includes(x.id)
        );
      } else {
        //Debemos agregar el de empresa
        const companyEstablishments = allCustomers.map((x) => ({
          id: "empresa",
          isForAllCustomer: true,
          customer: { id: x.id, alias: x.alias },
        }));
        establishments = [].concat(companyEstablishments, establishments);
      }
    }

    return establishments;
  },
  allLawyers: ({ allLawyers }) => allLawyers,
  allFilters: ({ allFilters }) => allFilters,
};

export default getters;
