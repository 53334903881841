var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.vueAppClasses,attrs:{"id":"app"}},[(
      _vm.isUserLoggedIn &&
      !(
        _vm.allFilters &&
        _vm.allCases &&
        _vm.allCivilCases &&
        _vm.allIssues &&
        _vm.allCustomers &&
        _vm.allEstablishments &&
        _vm.allZones &&
        _vm.allLawyers
      )
    )?_c('div',{staticClass:"custom-loading"},[_vm._m(0)]):_c('router-view',{on:{"setAppClasses":_vm.setAppClasses}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading"},[_c('div',{staticClass:"effect-1 effects"}),_c('div',{staticClass:"effect-2 effects"}),_c('div',{staticClass:"effect-3 effects"})])}]

export { render, staticRenderFns }