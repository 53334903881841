/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import { auth } from "@/firebase/firebaseConfig";

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router);

let mainRoutes = [];

//Casos PENALES
mainRoutes.push({
  path: "/main/cases",
  name: "main-cases",
  component: () => import("@/views/main/cases/Cases.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.list",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/cases/new",
  name: "main-cases-new",
  component: () => import("@/views/main/cases/CasesNew.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.new",
    parent: "main-cases",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/cases/cid/:cid",
  name: "main-cases-cid-edit",
  component: () => import("@/views/main/cases/CasesCIDHub.vue"),
  meta: {
    authRequired: true,
    hideNavbarTitle: true,
    parent: "main-cases",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/cases/:id",
  name: "main-cases-edit",
  component: () => import("@/views/main/cases/CasesEdit.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.edit",
    parent: "main-cases",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/issues",
  name: "main-issues",
  component: () => import("@/views/main/issues/Issues.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.issues.list",
    rule: "customer",
  },
});
mainRoutes.push({
  path: "/main/issues/new",
  name: "main-issues-new",
  component: () => import("@/views/main/issues/IssuesNewHub.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.issues.new",
    parent: "main-issues",
    rule: "customer",
  },
});
mainRoutes.push({
  path: "/main/issues/:id",
  name: "main-issues-edit",
  component: () => import("@/views/main/issues/IssuesEdit.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.issues.edit",
    parent: "main-issues",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/calendar",
  name: "main-calendar",
  component: () => import("./views/main/calendar/Calendar.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.calendar",
    rule: "customer",
  },
});
//Casos PENALES

//Casos CIVILES
mainRoutes.push({
  path: "/civil/civilCases",
  name: "civil-cases",
  component: () => import("@/views/civil/cases/CivilCases.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.civilCases.list",
    rule: "customer",
  },
});
mainRoutes.push({
  path: "/civil/civilCases/new",
  name: "civil-cases-new",
  component: () => import("@/views/civil/cases/CivilCasesNew.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.civilCases.new",
    parent: "civil-cases",
    rule: "customer",
  },
});
mainRoutes.push({
  path: "/civil/civilCases/:id",
  name: "civil-cases-edit",
  component: () => import("@/views/civil/cases/CivilCasesEdit.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.civilCases.edit",
    parent: "civil-cases",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/civil/calendar",
  name: "civil-calendar",
  component: () => import("./views/civil/calendar/CivilCalendar.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.civilCases.calendar",
    rule: "customer",
  },
});
//Casos CIVILES

mainRoutes.push({
  path: "/main/search",
  name: "main-search",
  component: () => import("./views/main/search/List.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.advancedSearch",
    rule: "customer",
  },
});

mainRoutes.push({
  path: "/main/teaching",
  name: "main-teaching",
  component: () => import("./views/main/teaching/List.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.teaching.list",
    rule: "customer",
  },
});
mainRoutes.push({
  path: "/main/teaching/:id",
  name: "main-teaching-edit",
  component: () => import("@/views/main/teaching/Edit.vue"),
  meta: {
    authRequired: true,
    pageTitle: "pages.cases.teaching.edit",
    parent: "main-teaching",
    rule: "customer",
  },
});

["Lawyers", "Bands", "Criminals", "Vehicles", "Zones", "Witnesses"].forEach(
  (name) => {
    let type = name.toLowerCase();
    const rule = ["Witnesses"].includes(name)
      ? "manager"
      : ["Zones"].includes(name)
      ? "supercustomerNotLawyer"
      : "customerNotLawyer";
    mainRoutes.push({
      path: `/settings/${name.toLowerCase()}`,
      name: `settings-${type}`,
      component: () => import(`@/views/settings/${type}/${name}.vue`),
      meta: {
        authRequired: true,
        pageTitle: `pages.${type}.list`,
        rule,
      },
    });
    mainRoutes.push({
      path: `/settings/${name.toLowerCase()}/:id`,
      name: `settings-${type}-edit`,
      component: () => import(`@/views/settings/${type}/${name}Edit.vue`),
      meta: {
        authRequired: true,
        pageTitle: `pages.${type}.edit`,
        parent: `settings-${type}`,
        rule,
      },
    });
  }
);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: mainRoutes.concat([
        // =============================================================================
        // Settings Routes
        // =============================================================================

        {
          path: "/settings/users",
          name: "settings-users",
          component: () => import("@/views/settings/users/Users.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.users.list",
            rule: "manager",
          },
        },
        {
          path: "/settings/users/:id",
          name: "settings-users-edit",
          component: () => import("@/views/settings/users/UsersEdit.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.users.edit",
            parent: "settings-users",
            rule: "manager",
          },
        },
        {
          path: "/settings/customers",
          name: "settings-customers",
          component: () => import("@/views/settings/customers/Customers.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.customers.list",
            rule: "manager",
          },
        },
        {
          path: "/settings/customers/new",
          name: "settings-customers-new",
          component: () =>
            import("@/views/settings/customers/CustomersNew.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.customers.new",
            parent: "settings-customers",
            rule: "manager",
          },
        },
        {
          path: "/settings/customers/:id",
          name: "settings-customers-edit",
          component: () =>
            import("@/views/settings/customers/CustomersEdit.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.customers.edit",
            parent: "settings-customers",
            rule: "manager",
          },
        },
        {
          path: "/settings/establishments",
          name: "settings-establishments",
          component: () =>
            import("@/views/settings/establishments/Establishments.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.establishments.list",
            rule: "customerNotLawyer",
          },
        },
        {
          path: "/settings/establishments/new",
          name: "settings-establishments-new",
          component: () =>
            import("@/views/settings/establishments/EstablishmentsNew.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.establishments.new",
            parent: "settings-establishments",
            rule: "manager",
          },
        },
        {
          path: "/settings/establishments/:id",
          name: "settings-establishments-edit",
          component: () =>
            import("@/views/settings/establishments/EstablishmentsEdit.vue"),
          meta: {
            authRequired: true,
            pageTitle: "pages.establishments.edit",
            parent: "settings-establishments",
            rule: "customerNotLawyer",
          },
        },

        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home-redirector",
          component: () => import("@/views/main/HomeRedirector.vue"),
          meta: {
            authRequired: true,
            pageTitle: "",
            rule: "customer",
          },
        },
        {
          path: "/dashboard/cases",
          name: "dashboard-cases",
          component: () => import("@/views/main/dashboard/Dashboard.vue"),
          meta: {
            pageTitle: "pages.cases.analytics",
            authRequired: true,
            rule: "customerNotLawyer",
          },
        },
        {
          path: "/dashboard/issues",
          name: "dashboard-issues",
          component: () => import("@/views/main/dashboard/Dashboard.vue"),
          meta: {
            pageTitle: "pages.issues.analytics",
            isForIssues: true,
            authRequired: true,
            rule: "customerNotLawyer",
          },
        },
        // =============================================================================
        // Application Routes
        // =============================================================================
        // {
        //   path: "/apps/calendar/vue-simple-calendar",
        //   name: "calendar-simple-calendar",
        //   component: () => import("./views/apps/calendar/SimpleCalendar.vue"),
        //   meta: {
        //     rule: "admin",
        //     no_scroll: true,
        //   },
        // },
        // {
        //   path: "/apps/eCommerce/shop",
        //   name: "ecommerce-shop",
        //   component: () => import("./views/apps/eCommerce/ECommerceShop.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "eCommerce" },
        //       { title: "Shop", active: true },
        //     ],
        //     pageTitle: "Shop",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/apps/eCommerce/wish-list",
        //   name: "ecommerce-wish-list",
        //   component: () =>
        //     import("./views/apps/eCommerce/ECommerceWishList.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "eCommerce", url: "/apps/eCommerce/shop" },
        //       { title: "Wish List", active: true },
        //     ],
        //     pageTitle: "Wish List",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/apps/eCommerce/checkout",
        //   name: "ecommerce-checkout",
        //   component: () =>
        //     import("./views/apps/eCommerce/ECommerceCheckout.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "eCommerce", url: "/apps/eCommerce/shop" },
        //       { title: "Checkout", active: true },
        //     ],
        //     pageTitle: "Checkout",
        //     rule: "admin",
        //   },
        // },
        /*
          Below route is for demo purpose
          You can use this route in your app
            {
                path: '/apps/eCommerce/item/',
                name: 'ecommerce-item-detail-view',
                redirect: '/apps/eCommerce/shop',
            }
        */
        // {
        //   path: "/apps/eCommerce/item/",
        //   redirect: "/apps/eCommerce/item/5546604",
        // },
        // {
        //   path: "/apps/eCommerce/item/:item_id",
        //   name: "ecommerce-item-detail-view",
        //   component: () =>
        //     import("./views/apps/eCommerce/ECommerceItemDetailView.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "eCommerce" },
        //       { title: "Shop", url: { name: "ecommerce-shop" } },
        //       { title: "Item Details", active: true },
        //     ],
        //     parent: "ecommerce-item-detail-view",
        //     pageTitle: "Item Details",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/apps/user/user-list",
        //   name: "app-user-list",
        //   component: () => import("@/views/apps/user/user-list/UserList.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "User" },
        //       { title: "List", active: true },
        //     ],
        //     pageTitle: "User List",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/apps/user/user-view/:userId",
        //   name: "app-user-view",
        //   component: () => import("@/views/apps/user/UserView.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "User" },
        //       { title: "View", active: true },
        //     ],
        //     pageTitle: "User View",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/apps/user/user-edit/:userId",
        //   name: "app-user-edit",
        //   component: () => import("@/views/apps/user/user-edit/UserEdit.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "User" },
        //       { title: "Edit", active: true },
        //     ],
        //     pageTitle: "User Edit",
        //     rule: "admin",
        //   },
        // },
        // =============================================================================
        // UI ELEMENTS
        // =============================================================================
        // {
        //   path: "/ui-elements/data-list/list-view",
        //   name: "data-list-list-view",
        //   component: () =>
        //     import(
        //       "@/views/ui-elements/data-list/list-view/DataListListView.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Data List" },
        //       { title: "List View", active: true },
        //     ],
        //     pageTitle: "List View",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/data-list/thumb-view",
        //   name: "data-list-thumb-view",
        //   component: () =>
        //     import(
        //       "@/views/ui-elements/data-list/thumb-view/DataListThumbView.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Data List" },
        //       { title: "Thumb View", active: true },
        //     ],
        //     pageTitle: "Thumb View",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/grid/vuesax",
        //   name: "grid-vuesax",
        //   component: () =>
        //     import("@/views/ui-elements/grid/vuesax/GridVuesax.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Grid" },
        //       { title: "Vuesax", active: true },
        //     ],
        //     pageTitle: "Grid",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/grid/tailwind",
        //   name: "grid-tailwind",
        //   component: () =>
        //     import("@/views/ui-elements/grid/tailwind/GridTailwind.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Grid" },
        //       { title: "Tailwind", active: true },
        //     ],
        //     pageTitle: "Tailwind Grid",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/colors",
        //   name: "colors",
        //   component: () => import("./views/ui-elements/colors/Colors.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Colors", active: true },
        //     ],
        //     pageTitle: "Colors",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/card/basic",
        //   name: "basic-cards",
        //   component: () => import("./views/ui-elements/card/CardBasic.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Card" },
        //       { title: "Basic Cards", active: true },
        //     ],
        //     pageTitle: "Basic Cards",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/card/statistics",
        //   name: "statistics-cards",
        //   component: () =>
        //     import("./views/ui-elements/card/CardStatistics.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Card" },
        //       { title: "Statistics Cards", active: true },
        //     ],
        //     pageTitle: "Statistics Card",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/card/analytics",
        //   name: "analytics-cards",
        //   component: () => import("./views/ui-elements/card/CardAnalytics.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Card" },
        //       { title: "Analytics Card", active: true },
        //     ],
        //     pageTitle: "Analytics Card",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/card/card-actions",
        //   name: "card-actions",
        //   component: () => import("./views/ui-elements/card/CardActions.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Card" },
        //       { title: "Card Actions", active: true },
        //     ],
        //     pageTitle: "Card Actions",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/card/card-colors",
        //   name: "card-colors",
        //   component: () => import("./views/ui-elements/card/CardColors.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Card" },
        //       { title: "Card Colors", active: true },
        //     ],
        //     pageTitle: "Card Colors",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/table",
        //   name: "table",
        //   component: () => import("./views/ui-elements/table/Table.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Table", active: true },
        //     ],
        //     pageTitle: "Table",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/ui-elements/ag-grid-table",
        //   name: "ag-grid-table",
        //   component: () =>
        //     import("./views/ui-elements/ag-grid-table/AgGridTable.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "agGrid Table", active: true },
        //     ],
        //     pageTitle: "agGrid Table",
        //     rule: "admin",
        //   },
        // },

        // =============================================================================
        // COMPONENT ROUTES
        // =============================================================================
        // {
        //   path: "/components/alert",
        //   name: "component-alert",
        //   component: () => import("@/views/components/vuesax/alert/Alert.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Alert", active: true },
        //     ],
        //     pageTitle: "Alert",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/avatar",
        //   name: "component-avatar",
        //   component: () =>
        //     import("@/views/components/vuesax/avatar/Avatar.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Avatar", active: true },
        //     ],
        //     pageTitle: "Avatar",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/breadcrumb",
        //   name: "component-breadcrumb",
        //   component: () =>
        //     import("@/views/components/vuesax/breadcrumb/Breadcrumb.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Breadcrumb", active: true },
        //     ],
        //     pageTitle: "Breadcrumb",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/button",
        //   name: "component-button",
        //   component: () =>
        //     import("@/views/components/vuesax/button/Button.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Button", active: true },
        //     ],
        //     pageTitle: "Button",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/button-group",
        //   name: "component-button-group",
        //   component: () =>
        //     import("@/views/components/vuesax/button-group/ButtonGroup.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Button Group", active: true },
        //     ],
        //     pageTitle: "Button Group",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/chip",
        //   name: "component-chip",
        //   component: () => import("@/views/components/vuesax/chip/Chip.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Chip", active: true },
        //     ],
        //     pageTitle: "Chip",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/collapse",
        //   name: "component-collapse",
        //   component: () =>
        //     import("@/views/components/vuesax/collapse/Collapse.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Collapse", active: true },
        //     ],
        //     pageTitle: "Collapse",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/dialogs",
        //   name: "component-dialog",
        //   component: () =>
        //     import("@/views/components/vuesax/dialogs/Dialogs.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Dialogs", active: true },
        //     ],
        //     pageTitle: "Dialogs",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/divider",
        //   name: "component-divider",
        //   component: () =>
        //     import("@/views/components/vuesax/divider/Divider.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Divider", active: true },
        //     ],
        //     pageTitle: "Divider",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/dropdown",
        //   name: "component-drop-down",
        //   component: () =>
        //     import("@/views/components/vuesax/dropdown/Dropdown.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Dropdown", active: true },
        //     ],
        //     pageTitle: "Dropdown",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/list",
        //   name: "component-list",
        //   component: () => import("@/views/components/vuesax/list/List.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "List", active: true },
        //     ],
        //     pageTitle: "List",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/loading",
        //   name: "component-loading",
        //   component: () =>
        //     import("@/views/components/vuesax/loading/Loading.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Loading", active: true },
        //     ],
        //     pageTitle: "Loading",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/navbar",
        //   name: "component-navbar",
        //   component: () =>
        //     import("@/views/components/vuesax/navbar/Navbar.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Navbar", active: true },
        //     ],
        //     pageTitle: "Navbar",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/notifications",
        //   name: "component-notifications",
        //   component: () =>
        //     import("@/views/components/vuesax/notifications/Notifications.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Notifications", active: true },
        //     ],
        //     pageTitle: "Notifications",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/pagination",
        //   name: "component-pagination",
        //   component: () =>
        //     import("@/views/components/vuesax/pagination/Pagination.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Pagination", active: true },
        //     ],
        //     pageTitle: "Pagination",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/popup",
        //   name: "component-popup",
        //   component: () => import("@/views/components/vuesax/popup/Popup.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Popup", active: true },
        //     ],
        //     pageTitle: "Popup",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/progress",
        //   name: "component-progress",
        //   component: () =>
        //     import("@/views/components/vuesax/progress/Progress.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Progress", active: true },
        //     ],
        //     pageTitle: "Progress",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/sidebar",
        //   name: "component-sidebar",
        //   component: () =>
        //     import("@/views/components/vuesax/sidebar/Sidebar.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Sidebar", active: true },
        //     ],
        //     pageTitle: "Sidebar",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/slider",
        //   name: "component-slider",
        //   component: () =>
        //     import("@/views/components/vuesax/slider/Slider.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Slider", active: true },
        //     ],
        //     pageTitle: "Slider",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/tabs",
        //   name: "component-tabs",
        //   component: () => import("@/views/components/vuesax/tabs/Tabs.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Tabs", active: true },
        //     ],
        //     pageTitle: "Tabs",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/tooltip",
        //   name: "component-tooltip",
        //   component: () =>
        //     import("@/views/components/vuesax/tooltip/Tooltip.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Tooltip", active: true },
        //     ],
        //     pageTitle: "Tooltip",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/components/upload",
        //   name: "component-upload",
        //   component: () =>
        //     import("@/views/components/vuesax/upload/Upload.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Components" },
        //       { title: "Upload", active: true },
        //     ],
        //     pageTitle: "Upload",
        //     rule: "admin",
        //   },
        // },

        // =============================================================================
        // FORMS
        // =============================================================================
        // =============================================================================
        // FORM ELEMENTS
        // =============================================================================
        // {
        //   path: "/forms/form-elements/select",
        //   name: "form-element-select",
        //   component: () =>
        //     import("./views/forms/form-elements/select/Select.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Select", active: true },
        //     ],
        //     pageTitle: "Select",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/switch",
        //   name: "form-element-switch",
        //   component: () =>
        //     import("./views/forms/form-elements/switch/Switch.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Switch", active: true },
        //     ],
        //     pageTitle: "Switch",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/checkbox",
        //   name: "form-element-checkbox",
        //   component: () =>
        //     import("./views/forms/form-elements/checkbox/Checkbox.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Checkbox", active: true },
        //     ],
        //     pageTitle: "Checkbox",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/radio",
        //   name: "form-element-radio",
        //   component: () =>
        //     import("./views/forms/form-elements/radio/Radio.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Radio", active: true },
        //     ],
        //     pageTitle: "Radio",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/input",
        //   name: "form-element-input",
        //   component: () =>
        //     import("./views/forms/form-elements/input/Input.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Input", active: true },
        //     ],
        //     pageTitle: "Input",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/number-input",
        //   name: "form-element-number-input",
        //   component: () =>
        //     import("./views/forms/form-elements/number-input/NumberInput.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Number Input", active: true },
        //     ],
        //     pageTitle: "Number Input",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-elements/textarea",
        //   name: "form-element-textarea",
        //   component: () =>
        //     import("./views/forms/form-elements/textarea/Textarea.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Form Elements" },
        //       { title: "Textarea", active: true },
        //     ],
        //     pageTitle: "Textarea",
        //     rule: "admin",
        //   },
        // },
        // -------------------------------------------------------------------------------------------------------------------------------------------
        // {
        //   path: "/forms/form-layouts",
        //   name: "forms-form-layouts",
        //   component: () => import("@/views/forms/FormLayouts.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Forms" },
        //       { title: "Form Layouts", active: true },
        //     ],
        //     pageTitle: "Form Layouts",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-wizard",
        //   name: "extra-component-form-wizard",
        //   component: () => import("@/views/forms/form-wizard/FormWizard.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Form Wizard", active: true },
        //     ],
        //     pageTitle: "Form Wizard",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-validation",
        //   name: "extra-component-form-validation",
        //   component: () =>
        //     import("@/views/forms/form-validation/FormValidation.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Form Validation", active: true },
        //     ],
        //     pageTitle: "Form Validation",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/forms/form-input-group",
        //   name: "extra-component-form-input-group",
        //   component: () =>
        //     import("@/views/forms/form-input-group/FormInputGroup.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Form Input Group", active: true },
        //     ],
        //     pageTitle: "Form Input Group",
        //     rule: "admin",
        //   },
        // },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        // {
        //   path: "/pages/profile",
        //   name: "page-profile",
        //   component: () => import("@/views/pages/Profile.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "Profile", active: true },
        //     ],
        //     pageTitle: "Profile",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/user-settings",
        //   name: "page-user-settings",
        //   component: () =>
        //     import("@/views/pages/user-settings/UserSettings.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "User Settings", active: true },
        //     ],
        //     pageTitle: "Settings",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/faq",
        //   name: "page-faq",
        //   component: () => import("@/views/pages/Faq.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "FAQ", active: true },
        //     ],
        //     pageTitle: "FAQ",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/knowledge-base",
        //   name: "page-knowledge-base",
        //   component: () => import("@/views/pages/KnowledgeBase.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "KnowledgeBase", active: true },
        //     ],
        //     pageTitle: "KnowledgeBase",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/knowledge-base/category",
        //   name: "page-knowledge-base-category",
        //   component: () => import("@/views/pages/KnowledgeBaseCategory.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "KnowledgeBase", url: "/pages/knowledge-base" },
        //       { title: "Category", active: true },
        //     ],
        //     parent: "page-knowledge-base",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/knowledge-base/category/question",
        //   name: "page-knowledge-base-category-question",
        //   component: () =>
        //     import("@/views/pages/KnowledgeBaseCategoryQuestion.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "KnowledgeBase", url: "/pages/knowledge-base" },
        //       { title: "Category", url: "/pages/knowledge-base/category" },
        //       { title: "Question", active: true },
        //     ],
        //     parent: "page-knowledge-base",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/search",
        //   name: "page-search",
        //   component: () => import("@/views/pages/Search.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "Search", active: true },
        //     ],
        //     pageTitle: "Search",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/pages/invoice",
        //   name: "page-invoice",
        //   component: () => import("@/views/pages/Invoice.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Pages" },
        //       { title: "Invoice", active: true },
        //     ],
        //     pageTitle: "Invoice",
        //     rule: "admin",
        //   },
        // },

        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        // {
        //   path: "/charts-and-maps/charts/apex-charts",
        //   name: "extra-component-charts-apex-charts",
        //   component: () =>
        //     import("@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Charts & Maps" },
        //       { title: "Apex Charts", active: true },
        //     ],
        //     pageTitle: "Apex Charts",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/charts-and-maps/charts/chartjs",
        //   name: "extra-component-charts-chartjs",
        //   component: () =>
        //     import("@/views/charts-and-maps/charts/chartjs/Chartjs.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Charts & Maps" },
        //       { title: "chartjs", active: true },
        //     ],
        //     pageTitle: "chartjs",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/charts-and-maps/charts/echarts",
        //   name: "extra-component-charts-echarts",
        //   component: () =>
        //     import("@/views/charts-and-maps/charts/echarts/Echarts.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Charts & Maps" },
        //       { title: "echarts", active: true },
        //     ],
        //     pageTitle: "echarts",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/charts-and-maps/maps/google-map",
        //   name: "extra-component-maps-google-map",
        //   component: () =>
        //     import("@/views/charts-and-maps/maps/google-map/GoogleMap.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Charts & Maps" },
        //       { title: "Google Map", active: true },
        //     ],
        //     pageTitle: "Google Map",
        //     rule: "admin",
        //   },
        // },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        // {
        //   path: "/extensions/select",
        //   name: "extra-component-select",
        //   component: () =>
        //     import("@/views/components/extra-components/select/Select.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Select", active: true },
        //     ],
        //     pageTitle: "Select",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/quill-editor",
        //   name: "extra-component-quill-editor",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/quill-editor/QuillEditor.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Quill Editor", active: true },
        //     ],
        //     pageTitle: "Quill Editor",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/drag-and-drop",
        //   name: "extra-component-drag-and-drop",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/drag-and-drop/DragAndDrop.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Drag & Drop", active: true },
        //     ],
        //     pageTitle: "Drag & Drop",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/datepicker",
        //   name: "extra-component-datepicker",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/datepicker/Datepicker.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Datepicker", active: true },
        //     ],
        //     pageTitle: "Datepicker",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/datetime-picker",
        //   name: "extra-component-datetime-picker",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/datetime-picker/DatetimePicker.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extra Components" },
        //       { title: "Datetime Picker", active: true },
        //     ],
        //     pageTitle: "Datetime Picker",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/access-control",
        //   name: "extra-component-access-control",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/access-control/AccessControl.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Access Control", active: true },
        //     ],
        //     pageTitle: "Access Control",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/i18n",
        //   name: "extra-component-i18n",
        //   component: () =>
        //     import("@/views/components/extra-components/I18n.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "I18n", active: true },
        //     ],
        //     pageTitle: "I18n",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/carousel",
        //   name: "extra-component-carousel",
        //   component: () =>
        //     import("@/views/components/extra-components/carousel/Carousel.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Carousel", active: true },
        //     ],
        //     pageTitle: "Carousel",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/clipboard",
        //   name: "extra-component-clipboard",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/clipboard/Clipboard.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Clipboard", active: true },
        //     ],
        //     pageTitle: "Clipboard",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/context-menu",
        //   name: "extra-component-context-menu",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/context-menu/ContextMenu.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Context Menu", active: true },
        //     ],
        //     pageTitle: "Context Menu",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/star-ratings",
        //   name: "extra-component-star-ratings",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/star-ratings/StarRatings.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Star Ratings", active: true },
        //     ],
        //     pageTitle: "Star Ratings",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/autocomplete",
        //   name: "extra-component-autocomplete",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/autocomplete/Autocomplete.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Autocomplete", active: true },
        //     ],
        //     pageTitle: "Autocomplete",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/extensions/tree",
        //   name: "extra-component-tree",
        //   component: () =>
        //     import("@/views/components/extra-components/tree/Tree.vue"),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Tree", active: true },
        //     ],
        //     pageTitle: "Tree",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/import-export/import",
        //   name: "import-excel",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/import-export/Import.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Import/Export" },
        //       { title: "Import", active: true },
        //     ],
        //     pageTitle: "Import Excel",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/import-export/export",
        //   name: "export-excel",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/import-export/Export.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Import/Export" },
        //       { title: "Export", active: true },
        //     ],
        //     pageTitle: "Export Excel",
        //     rule: "admin",
        //   },
        // },
        // {
        //   path: "/import-export/export-selected",
        //   name: "export-excel-selected",
        //   component: () =>
        //     import(
        //       "@/views/components/extra-components/import-export/ExportSelected.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: "Home", url: "/" },
        //       { title: "Extensions" },
        //       { title: "Import/Export" },
        //       { title: "Export Selected", active: true },
        //     ],
        //     pageTitle: "Export Excel",
        //     rule: "admin",
        //   },
        // },
      ]),
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/login/Login.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/register/Register.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "admin",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

/*
router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged(() => {

        // get firebase current user
        const firebaseCurrentUser = firebase.auth().currentUser

    

        // if (
        //     to.path === "/pages/login" ||
        //     to.path === "/pages/forgot-password" ||
        //     to.path === "/pages/error-404" ||
        //     to.path === "/pages/error-500" ||
        //     to.path === "/pages/register" ||
        //     to.path === "/callback" ||
        //     to.path === "/pages/comingsoon" ||
        //     (auth.isAuthenticated() || firebaseCurrentUser)
        // ) {
        //     return next();
        // }

        // If auth required, check login. If login fails redirect to login page
        if(to.meta.authRequired) {
          if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
            router.push({ path: '/login', query: { to: to.path } })
          }
        }

        return next()
        // Specify the current path as the customState parameter, meaning it
        // will be returned to the application after auth
        // auth.login({ target: to.path });

    });

});
*/

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((x) => x.meta.authRequired);

  let isAuthenticated = false;
  if (firebase.auth().currentUser) {
    const idToken = await firebase.auth().currentUser.getIdTokenResult();
    isAuthenticated = idToken.claims.penalRetailAllowed;
  }

  const currentUser = isAuthenticated ? firebase.auth().currentUser : null;

  if (authRequired && !currentUser) {
    next({
      path: "/login",
      query: {
        to: to.path,
      },
    });
  } else if (!authRequired && currentUser) {
    next("/");
  } else {
    next();
  }
});

export default router;
