<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <div
      class="custom-loading"
      v-if="
        isUserLoggedIn &&
        !(
          allFilters &&
          allCases &&
          allCivilCases &&
          allIssues &&
          allCustomers &&
          allEstablishments &&
          allZones &&
          allLawyers
        )
      "
    >
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
    <router-view @setAppClasses="setAppClasses" v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeConfig from "@/../themeConfig.js";
import jwt from "@/http/requests/auth/jwt/index.js";
import { db } from "@/firebase/firebaseConfig";
import { auth } from "@/firebase/firebaseConfig";
import { Validator } from "vee-validate";

export default {
  data() {
    return {
      isUserLoggedIn: false,
      vueAppClasses: [],
    };
  },
  computed: {
    ...mapGetters([
      "allCases",
      "allCivilCases",
      "allIssues",
      "allCustomers",
      "allEstablishments",
      "allZones",
      "allLawyers",
      "allFilters",
    ]),
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
    isUserLoggedIn() {
      this.updateBindings();
    },
    "$store.state.AppActiveUser.uid"() {
      this.updateBindings();
    },
  },
  methods: {
    updateBindings() {
      if (this.isUserLoggedIn && !!this.$store.state.AppActiveUser.uid) {
        if (this.$store.state.AppActiveUser.locale) {
          Validator.localize(this.$store.state.AppActiveUser.locale);
          this.$i18n.locale = this.$store.state.AppActiveUser.locale;
        }

        this.$store.dispatch("bindAllFilters", db);
        this.$store.dispatch("bindAllCases", db);
        this.$store.dispatch("bindAllCivilCases", db);
        this.$store.dispatch("bindAllIssues", db);
        this.$store.dispatch("bindAllCustomers", db);
        this.$store.dispatch("bindAllEstablishments", db);
        this.$store.dispatch("bindAllZones", db);
        this.$store.dispatch("bindAllLawyers", db);
      } else {
        this.$store.dispatch("unbind", "allFilters");
        this.$store.dispatch("unbind", "allCases");
        this.$store.dispatch("unbind", "allCivilCases");
        this.$store.dispatch("unbind", "allIssues");
        this.$store.dispatch("unbind", "allCustomers");
        this.$store.dispatch("unbind", "allEstablishments");
        this.$store.dispatch("unbind", "allZones");
        this.$store.dispatch("unbind", "allLawyers");
      }
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    auth.onAuthStateChanged(async () => {
      this.isUserLoggedIn =
        await this.$store.state.auth.isPenalRetailUserLoggedIn();
    });
  },
  async created() {
    // jwt
    jwt.init();

    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);

    // Auth0
    // try       { await this.$auth.renewTokens() }
    // catch (e) { console.error(e) }

    this.updateBindings();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.custom-loading {
  position: relative;
  width: 100%;
  height: 100vh;

  .loading {
    top: calc(50% - 35px);
  }
}
</style>
