/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { firestoreAction } from 'vuexfire'
import firebase from "firebase/app";

const actions = {
  bindAllCases: firestoreAction(({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;

    let criteria = db.collection('cases');

    if (["supercustomer", "customer"].includes(user.role)) {
      criteria = criteria.where("customer.id", "==", user.customer.id);
    }

    criteria = criteria.where("deleted", "==", false).orderBy("cid", "desc")
    return bindFirestoreRef('allCases', criteria, { wait: true });
  }),
  bindAllCivilCases: firestoreAction(({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;

    let criteria = db.collection('civilCases');

    if (["supercustomer", "customer"].includes(user.role)) {
      criteria = criteria.where("customer.id", "==", user.customer.id);
    }

    criteria = criteria.where("deleted", "==", false).orderBy("cid", "desc")
    return bindFirestoreRef('allCivilCases', criteria, { wait: true });
  }),
  bindAllIssues: firestoreAction(({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;

    let criteria = db.collection('issues');

    if (["supercustomer", "customer"].includes(user.role)) {
      criteria = criteria.where("customer.id", "==", user.customer.id);
    }

    criteria = criteria.where("deleted", "==", false).orderBy("cid", "desc")
    return bindFirestoreRef('allIssues', criteria, { wait: true });
  }),
  bindAllCustomers: firestoreAction(({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;

    let criteria = db.collection("customers").where("deleted", "==", false);

    if (["supercustomer", "customer"].includes(user.role)) {
      criteria = criteria.where(firebase.firestore.FieldPath.documentId(), "==", user.customer.id);
    } else {
      criteria = criteria.orderBy("alias")
    }
    return bindFirestoreRef('allCustomers', criteria, { wait: true });
  }),
  bindAllEstablishments: firestoreAction(async ({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;

    let criteria = db.collection("establishments").where("deleted", "==", false);

    if (["supercustomer", "customer"].includes(user.role)) {
      criteria = criteria.where("customer.id", "==", user.customer.id);
    }

    criteria = criteria.orderBy("alias");

    //Dejamos el filtrado específico por establecimiento para el getter, ya que firebase no permite filtrado por más de 10 entidades
    //TODO: AGREGAR LA EMPRESA GLOBAL DE ALGUNA MANERA
    return bindFirestoreRef('allEstablishments', criteria, { wait: true });
  }),
  bindAllZones: firestoreAction(async ({ bindFirestoreRef, state }, db) => {
    const user = state.AppActiveUser;
    if (["lawyer", "customer"].includes(user.role)) {
      //Los lawyers y customers no ven nada
      return [];
    } else {
      let criteria = db.collection("zones").where("deleted", "==", false);

      if (["supercustomer"].includes(user.role)) {
        criteria = criteria.where("customer.id", "==", user.customer.id);
      }

      criteria = criteria.orderBy("alias");

      return bindFirestoreRef('allZones', criteria, { wait: true });
    }
  }),
  bindAllLawyers: firestoreAction(({ bindFirestoreRef }, db) => {
    return bindFirestoreRef('allLawyers', db.collection("lawyers").where("deleted", "==", false).orderBy("alias"), { wait: true });
  }),
  bindAllFilters: firestoreAction(({ bindFirestoreRef, state }, db) => {
    return bindFirestoreRef('allFilters', db.collection(`users/${state.AppActiveUser.uid}/filters`), { wait: true });
  }),

  unbind: firestoreAction(({ unbindFirestoreRef }, collectionName) => {
    unbindFirestoreRef(collectionName)
  }),

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    updateUserRole({ dispatch }, payload) {
      // Change client side
      payload.aclChangeRole(payload.role)

      // Make API call to server for changing role

      // Change userInfo in localStorage and store
      dispatch('updateUserInfo', {role: payload.role})
    },
}

export default actions
