// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html


import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage';
import "firebase/functions"

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth()
export const functions = firebase.app().functions("europe-west2")

//Esto está hecho para poner disponible para el administrador, la bds para poder hacer cambios en la consola (por ejemplo, agregar juzgados)
auth.onAuthStateChanged(async userData => {
  delete window.penalRetail;
  let isAuthenticated = false;
  if (userData) {
    const idToken = await userData.getIdTokenResult();
    isAuthenticated = idToken.claims.penalRetailAllowed || false;
  }

  if (isAuthenticated) {
    let userSnap = await db.collection("users").doc(userData.uid).get();
    if (userSnap.exists) {
      let user = userSnap.data();
      if (user.role === "admin") {
        window.penalRetail = { db };
      }
    }
  }
});