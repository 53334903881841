import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

export default new AclCreate({
  initial: "public",
  notfound: "/pages/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule("admin").generate(),
    manager: new AclRule("manager").or("admin").generate(),
    lawyer: new AclRule("lawyer").or("manager").or("admin").generate(),
    supercustomerNotLawyer: new AclRule("supercustomer").or("manager").or("admin").generate(),
    supercustomer: new AclRule("supercustomer").or("lawyer").or("manager").or("admin").generate(),
    customer: new AclRule("customer").or("supercustomer").or("lawyer").or("manager").or("admin").generate(),
    customerNotLawyer: new AclRule("customer").or("supercustomer").or("manager").or("admin").generate(),
    public: new AclRule("public").or("customer").or("supercustomer").or("lawyer").or("manager").or("admin").generate()
  },
  middleware: async acl => {
    let userInfo = await JSON.parse(localStorage.getItem("userInfo"))
    userInfo && userInfo.role ? acl.change(userInfo.role) : acl.change("public")
  }
})
